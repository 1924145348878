<template>
  <BModal
    id="modal-delete-ads"
    ref="modal-delete-ads"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    body-class="p-2"
  >
    <div class="flex flex-col gap-6 pt-6">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="mx-auto"
        width="100"
        height="100"
      />
      <div class="flex flex-col gap-2 text-center">
        <h1 class="font-bold text-2xl">
          Konfirmasi Hapus Iklan
        </h1>
        <p>
          Iklan yang dihapus tidak bisa dikembalikan, tapi insight-mu tetap
          aman. Yakin hapus iklan?
        </p>
      </div>
      <div class="flex flex-row gap-4">
        <BButton
          class="grow"
          variant="outline-primary"
          @click="$refs['modal-delete-ads'].hide()"
        >
          Batal
        </BButton>
        <BButton
          class="grow"
          variant="primary"
          :disabled="loading"
          @click="deleteData"
        >
          <BSpinner v-if="loading" small />
          <span :class="{ 'ml-2': loading }">Hapus</span>
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { toast_error, toast_success } from '@/libs/toastification'
import { BButton } from 'bootstrap-vue'

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async deleteData() {
      this.loading = true
      try {
        await newAxiosIns.delete(`/komads/api/v1/ads/${this.id}/delete`)
        this.loading = false
        this.$refs['modal-delete-ads'].hide()
        this.sendMessage()
        toast_success({ title: 'Berhasil', message: 'Iklan berhasil dihapus' })
      } catch (error) {
        this.loading = false
        this.$refs['modal-delete-ads'].hide()
        toast_error({
          title: 'Gagal',
          message:
            'Iklan gagal dihapus karena belum 24 jam semenjak dinonaktifkan',
        })
      }
    },
    sendMessage() {
      const message = { type: 'REFETCH_GET_ADS' }
      const iframe = document.querySelector('#komads-container iframe')
      iframe.contentWindow.postMessage(message, '*')
    },
  },
}
</script>
