<template>
  <div>
    <div id="komads-container" />
    <ModalTopup :title="modalTitle" />
    <ModalMaxChancePIN />
    <ModalChangeStatusAds
      :id="idAds"
      :ads-balance="adsBalance"
      :daily-budget="dailyBudget"
      :ads-start-date="adsStartDate"
      :ads-end-date="adsEndDate"
    />
    <ModalPreviewAds :id="idAds" />
    <ModalDeleteAds :id="idAds" />
    <ModalEditViewer
      :id="idAds"
      :age="age"
      :gender="gender"
      :location="location"
      :category="category"
    />
  </div>
</template>

<script>
import { toast_error, toast_info } from '@/libs/toastification'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'
import { IDR } from '@/libs/currency'
import ModalTopup from './ModalTopup.vue'
import ModalChangeStatusAds from './ModalChangeStatusAds.vue'
import ModalPreviewAds from './ModalPreviewAds.vue'
import ModalEditViewer from './ModalEditViewer.vue'
import ModalDeleteAds from './ModalDeleteAds.vue'

export default {
  components: {
    ModalTopup,
    ModalMaxChancePIN,
    ModalChangeStatusAds,
    ModalPreviewAds,
    ModalEditViewer,
    ModalDeleteAds,
  },
  data() {
    return {
      modalTitle: null,
      idAds: null,
      adsBalance: null,
      dailyBudget: null,
      adsStartDate: null,
      adsEndDate: null,
      age: null,
      gender: null,
      location: null,
      category: null,
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = `${process.env.VUE_APP_BASE_AUTH_MODULE}/komads.js`
    script.async = true

    document.head.appendChild(script)

    window.addEventListener(
      'message',
      event => {
        const {
          adsBalance,
          dailyBudget,
          adsStartDate,
          adsEndDate,
          route,
          modal,
          toast,
          meta_message,
          id_ads,
          location,
          category,
          age,
          gender,
        } = event.data

        if (modal === 'MODAL_TOPUP') {
          this.$bvModal.show('modal-topup-komads')
          this.modalTitle = 'Top Up Saldo Komads'
          return
        }

        if (modal === 'MODAL_VIEWER_ADS') {
          this.idAds = id_ads
          this.age = age
          this.gender = gender
          this.location = location
          this.category = category
          this.$bvModal.show('modal-edit-viewer')
          return
        }

        if (modal === 'MODAL_MAX_TRY_PIN') {
          this.$bvModal.show('max-chance-PIN')
          return
        }

        if (modal === 'MODAL_WARNING_ADS_ON_PROGRESS') {
          this.$swal({
            title: 'Mohon Tunggu Beberapa Saat',
            text:
              ' Mohon tunggu 1-10 menit, dan kamu bisa segera menggunakannya. Terima kasih atas kesabarannya',
            icon: 'warning',
            iconHtml:
              '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            confirmButtonText: 'Oke',
            customClass: {
              icon: 'border-0 w-25',
              confirmButton: 'btn btn-primary btn-block',
            },
          })
        }

        if (modal === 'MODAL_CHANGE_STATUS_ADS_ACTIVE') {
          this.idAds = id_ads
          this.adsBalance = adsBalance
          this.dailyBudget = IDR(dailyBudget)
          this.adsStartDate = adsStartDate
          this.adsEndDate = adsEndDate
          this.$bvModal.show('modal-change-status-ads-active')
          return
        }

        if (modal === 'MODAL_PREVIEW_ADS') {
          this.idAds = id_ads
          this.$bvModal.show('modal-preview-ads')
        }

        if (modal === 'MODAL_DELETE_ADS') {
          this.idAds = id_ads
          this.$bvModal.show('modal-delete-ads')
        }

        if (toast === 'ERROR_ONBOARDING') {
          toast_error({
            title: 'Error',
            message: 'Terjadi kesalahan saat melakukan onboarding',
          })
        }

        if (toast === 'CANCEL_EDIT_ADS') {
          toast_info({
            title: 'Peringatan',
            message: 'Perubahan tidak disimpan',
          })
        }

        if (toast === 'ERROR_CLAIMS_PAGES') {
          toast_error({
            title: 'Error',
            message: meta_message,
          })
        }

        if (toast === 'ERROR_GET_ADS') {
          toast_error({
            title: 'Gagal',
            message: 'Gagal mengambil data status akun iklan',
          })
        }

        if (toast === 'ERROR_GET_PAGE_LIST') {
          toast_error({
            title: 'Gagal',
            message: 'Gagal mengambil data halaman facebook',
          })
        }

        if (toast === 'ERROR_GET_STATUS_ACCOUNT') {
          toast_error({
            title: 'Gagal',
            message: 'Gagal mengambil data status akun iklan',
          })
        }

        if (route) {
          this.$router.push(route)
        }
      },
      false,
    )
  },
}
</script>
